<template>
  <div>
    <logistic-list />
  </div>
</template>

<script>
import LogisticList from '@/components/logistic/LogisticList.vue'

export default {
  components: {
    LogisticList,
  },
}
</script>
