<template>
  <div>
    <b-modal
      v-model="showModal"
      ok-only
      ok-title="Accept"
      centered
      size="lg"
      :hide-footer="true"
      lazy
      static
      @hidden="$emit('close')"
    >
      <div>
        <div v-if="sku">
          <div>
            <b-row class="breadcrumbs-top mb-2">
              <b-col cols="6">
                <h4>SKU: {{ sku.sku }}</h4>
              </b-col>
            </b-row>
            <b-row class="breadcrumbs-top mb-2">
              <b-col cols="6 d-flex">
                <p><strong><u>Delay of shipping:</u></strong></p>
                <div class="">
                  <b-form-input
                    v-model="sku.delay"
                    placeholder="Number of days"
                    style="height: auto;"
                    class="border-0 shadow-none"
                  />
                  <!-- <p>days</p> -->
                </div>
              </b-col>
              <b-col cols="6 d-flex">
                <p><strong><u>Stock Limit (minimum stock for order):</u></strong></p>
                <div class="">
                  <b-form-input
                    v-model="sku.stockLimit"
                    placeholder="Minimum stock"
                    style="height: auto;"
                    class="border-0 shadow-none"
                  />
                  <!-- <p>days</p> -->
                </div>
              </b-col>
              <b-col cols="6">
                <p><strong><u>Locked Stock Update:</u></strong></p>
                <div class="">
                  <flat-pickr
                    v-model="sku.dateStockUpdate"
                    placeholder="Choisissez une date"
                    class="form-control mr-1"
                  />
                </div>
              </b-col>
              <b-col cols="6">
                <p><strong><u>Compose sku:</u></strong></p>
                <div class="">
                  <b-form-input
                    v-model="sku.composeSku"
                    placeholder="Sku composé (exemple: COMPOSANT1,COMPOSANT2)"
                    style="height: auto;"
                  />
                </div>
              </b-col>
            </b-row>
            <b-row class="breadcrumbs-top mb-2">
              <b-col cols="6">
                <p><strong><u>Dispatch Rules:</u></strong>
                  <v-select
                    v-model="sku.logCategory"
                    :options="categories"
                    transition=""
                  />
                </p>
                <div
                  v-for="(dispatch, keyD) of rules.filter(r => r.category === sku.logCategory)"
                  :key="keyD"
                >
                  <p><strong><u>Countries:</u></strong> {{ dispatch.country.join(' / ') }}</p>
                  <ul>
                    <li
                      v-for="(solution, keyS) of dispatch.solutions"
                      :key="keyS"
                    >
                      <strong><u>Log:</u></strong> {{ solution.logistic }} / <strong><u>Shipper:</u></strong> {{ solution.transport }} / <strong><u>Level:</u></strong> {{ solution.level }}
                    </li>
                  </ul>
                </div>
              </b-col>
              <b-col cols="6">
                <b-row v-for="key of ['length', 'width', 'height', 'weight']" :key="key" class="mt-1">
                  <b-col cols="2">
                    <p><strong><u>{{ key }} ({{ key === 'weight' ? 'kg' : 'cm' }}):</u></strong></p>
                  </b-col>
                  <b-col cols="6">
                    <b-form-input
                      v-model="sku.dimension[key]"
                      :placeholder="key"
                      style="height: auto;"
                    />
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <b-row v-if="sku.orderBl && sku.orderBl.length" class="breadcrumbs-top mb-2">
              <p><strong><u>Prochaines livraisons:</u></strong></p>
              <b-table-lite
                responsive
                :items="sku.orderBl"
                :fields="['id', 'remaining', {key:'date', 'label': 'Date Order'}, 'dateShipping']"
              >
                <template #cell(id)="data">
                  <a href="/order-upload" target="_blank" class="text-primary">{{ data.item.id }}</a>
                </template>
                <template #cell(date)="data">
                  {{ $moment(data.item.date).format('DD/MM/YYYY') }}
                </template>
                <template #cell(dateShipping)="data">
                  {{ $moment(data.item.dateShipping).format('DD/MM/YYYY') }}
                </template>
                <template #cell(dateReceived)="data">
                  {{ data.item.dateReceived }}
                </template>
                <template #cell(received)="data">
                  {{ data.item.quantity - data.item.remaining }}
                </template>
              </b-table-lite>
            </b-row>
            <b-row class="breadcrumbs-top mb-2 ml-2">
              <b-button
                :disabled="loading"
                variant="primary"
                @click="save"
              >
                Enregistrer
              </b-button>
            </b-row>
          </div>
        </div>

      </div>
    </b-modal>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BFormInput,
  BModal,
  VBPopover,
  BButton,
  BTableLite,
} from 'bootstrap-vue'
import { required } from '@validations'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BFormInput,
    BRow,
    BCol,
    flatPickr,
    BTableLite,
    // BBadge,
    BModal,
    vSelect,
    BButton,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContentVue,
  },
  directives: {
    'b-popover': VBPopover,
    Ripple,
  },
  props: {
    sku: {
      type: Object,
      required: true,
    },
    showModal: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      required,
      baseLogistics: [],
      logistics: [],
      products: [],
      categories: [],
      logistic: {},
      code: '',
      rules: null,
      loading: false,
    }
  },
  async mounted() {
    try {
      const { rules } = await this.$http.get('/admin/logistic/rules')
      this.rules = rules
      this.categories = [...new Set(this.rules.map(r => r.category))]
    } catch (err) {
      console.log(err)
    }
  },
  methods: {
    openModal(element) {
      console.log(element)
      this.sku = element
      this.showModal = true
    },
    async save() {
      try {
        this.loading = true
        await this.$http.post(`/admin/logistic/${this.sku._id}/dispatch`, { log: this.sku })
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: 'Bien sauvegardée.',
            icon: 'EditIcon',
            variant: 'success',
          },
        })
        this.$emit('close')
        this.sku = null
        // this.getLogistics()
      } catch (err) {
        console.log(err)
      } finally {
        this.loading = false
      }
    },
  },
}
</script>

  <style>
   .jsoneditor-poweredBy {
     display: none;
   }
  </style>

  <style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
  @import '@core/scss/vue/libs/vue-flatpicker.scss';
  </style>
